@font-face {
  font-family: Lemon;
  src: url("./assets/fonts/LEMONMILK-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: Lemon_Medium;
  src: url("./assets/fonts/LEMONMILK-Medium.otf");
  font-weight: 700;
}

@font-face {
  font-family: Jost;
  src: url("./assets/fonts/Jost-VariableFont_wght.ttf");
  font-weight: 700;
}

#container {
  position: fixed;
  touch-action: none;
}

/* CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (min-width: 1400px) {
  .App {
    /* width: 1370px; */
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  /* background-color: bisque !important; */
  background-color: transparent;
  color: black;
  font-family: "Lemon", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/************ Navbar Css ************/
.major {
  display: flex;
  flex-direction: row;
  margin-inline: 80px;
}
.school-icon {
  display: flex;
}
.social-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.social-icon a {
  color: #3c6255;
  width: 42px;
  height: 42px;
  aspect-ratio: 1/1;
  background: rgba(47, 47, 47, 0.1);
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  margin-inline: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(97, 96, 96, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #a6bb8d;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a .icon {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover .icon {
  filter: brightness(0) invert(1);
}

nav {
  background: rgba(33, 33, 33, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}
nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}
nav a:hover {
  background: rgba(0, 0, 0, 0.3);
  color: #eae7b1;
}
nav a.active {
  background: var(--color-bg);
  color: var(--color-white);
  background-color: #f8ede3;
}
.head-text {
  color: #f8ede3;
  font-size: 40px;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 30px;
}

/************ Banner Css ************/
.banner {
  padding: 260px 0 300px 0;
  /* background-image: url('./assets/images/banner.jpg'); */
  background-color: #f8ede3;
  /* background-position: top center;
  background-size: cover;
  background-repeat: no-repeat; */
}
.banner h1 {
  color: #3c6255;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner .cursor {
  font-size: 65px;
  padding-right: 20px;
  display: inline-block;
}
.cursor:after {
  content: "";
  width: 20px;
  height: 2px;
  background: black;
  opacity: 0;
  display: inline-block;
  animation: blink 1s linear infinite alternate;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.banner p {
  color: #565656;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: rgb(131, 67, 0);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
  margin-left: 50px;
}

/************ Experience Css ************/
.experience {
  /* background-color: #85586F; #F8EDE3*/
  /* background: linear-gradient(to bottom right, #82AAE3, #FCFFE7); */
  /* background: linear-gradient(to bottom right, #FCE38A, #F38181); */
  /* background: linear-gradient(to bottom right, #F8EDE3, #BDD2B6); */
  /* background: linear-gradient(to bottom right, #FFDE7D, #F6416C); */
  /* background: linear-gradient(to bottom right, #A6BB8D, #EAE7B1); */
  /* background: linear-gradient(to bottom right, #7D6E83, #9E7676); */
  background-color: #3c6255;
  background-size: cover;
  padding: 40px 0 60px 0;
}
.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}
.app__skills-container {
  width: 80%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 5rem;
  margin-left: 5rem;
}
.app__skills-item {
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  transition: all 0.3s ease-in-out;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 0 25px #ababab;
  margin-bottom: 60px;
}

@media screen and (max-width: 900px) {
  .app__skills-container {
    padding-left: 20px;
    width: 100%;
    flex-direction: column;
  }
  .app__skills-list {
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }
  .app__skills-item {
    width: 60px;
    height: 60px;
  }
}
.p-text {
  padding-top: 15px;
  text-align: center;
  color: #f8ede3;
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}
.company-text {
  padding-top: 15px;
  text-align: left;
}
.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}
.app__skills-exp-works {
  flex: 1;
}
.app__skills-exp-work {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
}

.app__skills-exp-work .work-desc {
  text-align: left;
}
.app__skills-exp-work h4 {
  color: #eae7b1;
  font-weight: 600;
}
.app__skills-exp-work p {
  font-weight: 400;
  color: #2a2a29;
  margin-top: 5px;
}
.app__skills-exp-year {
  margin-right: 3rem;
  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}
.app__skills-exp-year p {
  font-weight: 800;
  color: #f8ede3;
}
.skills-tooltip {
  max-width: 300px !important;
  background-color: #f8ede3 !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: left !important;
  line-height: 1.5 !important;
  opacity: 1 !important;
  font-style: italic;
  /* transform: translateX(120%) translateY(-100%); */
}

@media screen and (min-width: 2000px) {
  .skills-tooltip {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}

/************ Projects Css ************/
.projects {
  padding: 80px 0;
  position: relative;
  /* background: linear-gradient(to top right, #82AAE3, #FCFFE7); */
  /* background: linear-gradient(to top right, #FCE38A, #F38181); */
  /* background: linear-gradient(to top right, #F8EDE3, #BDD2B6); */
  /* background: linear-gradient(to top right, #FFDE7D, #F6416C); */
  background-color: #3c6255;
}
.project h2 {
  padding-bottom: 35px;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(198, 180, 216, 1) 55%,
    rgba(148, 187, 233, 1) 100%
  );
  opacity: 0.95;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
.icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.web-icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding-top: 15px;
}
.web-icon:hover {
  filter: brightness(0) invert(1);
}
.git-icon {
  content: "";
  width: 42px;
  height: 42px;
  /* position: absolute; */
  border-radius: 50%;
  padding-top: 15px;
}
.git-icon:hover {
  filter: brightness(0) invert(1);
}

/************ Contact Css ************/
/* Google Font CDN Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.contact {
  background-color: #f8ede3;
  padding: 60px 0 200px 0;
}
.contact h2 {
  color: #3c6255;
  font-size: 40px;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 30px;
}
.connect {
  margin: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  background: #3c6255;
  border-radius: 6px;
  padding: 50px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.connect .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.connect .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #dfd3c3;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #7a7a7a;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #ffffff;
}
.connect .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #eae7b1;
  text-align: left;
}
.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #dfd3c3;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"] {
  color: rgb(0, 0, 0);
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #a6bb8d;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover {
  background: #b0cfbb;
}

@media (max-width: 950px) {
  .connect {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .connect .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .connect {
    margin: auto;
    width: 85%;
    height: 100%;
  }
  .connect .content {
    flex-direction: column-reverse;
  }
  .connect .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .connect .content .left-side::before {
    display: none;
  }
  .connect .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}

/************ Timeline Css ************/
.timeline {
  background: #add8e6;
  font-size: 14px;
  color: rgb(53, 53, 53);
}

.timeline .title {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 5em;
  text-align: center;
  font-family: Lemon_Medium;
}

.timeline .h3 {
  padding-top: 0.25em;
}

.timeline .vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.timeline .date {
  color: rgb(255, 255, 255);
  font-family: Lemon_Medium;
}

.timeline .description {
  margin: 1.5em 0 2em 0;
  /* font-family: Jost; */
}

/************ App Css ************/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
